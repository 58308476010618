import React, { ReactElement } from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const Txt: React.FunctionComponent = props => {
  return <p className="py-2">{props.children}</p>
}

function Privacy(): ReactElement {
  return (
    <>
      <Header page={"Privacy"} />
      <div className="w-full flex flex-col items-center justify-center">
        <div className="py-2 px-2 md:py-10 md:px-32 text-md md:text-xl text-justify text-gray-800 font-bold">
          <p className="pb-8 text-3xl text-center font-bold uppercase">
            Privacy Policy
          </p>
          <Txt>
            We don't collect any personally identifiable information about our
            users. Our apps don’t actively collect any information from
            children. Our apps may collect technical information which does not
            specifically identify a user, but supports the operation of the app.
            We do not use any analytics for statistics or data on the apps.
          </Txt>
          <Txt>
            We understand the importance of our users’ personal information and
            particularly with regards to children under 13 years of age. We
            don’t collect or require users to enter their personal information
            when using our apps. We don't use technology to collect and disclose
            to a third party a user's personal information such as name, gender,
            age, address, email address, etc.
          </Txt>
          <Txt>
            Our apps don't use or collect a user's precise geographic location.
            Our apps don't contain social network integration or other social
            features (active links to Facebook, Twitter, or any other social
            network or service).
          </Txt>
          <Txt>
            We use the following providers to serve contextual ads inside the
            apps. These networks are part of the GooglePlay and iTunes certified
            ad network program. This PRIVACY POLICY also applies to Cake Maker,
            and Animal Puppy Pet Vet on GooglePlay. Kidoz and Admob contextual
            ads are displayed inside Cake Maker, and Animal Puppy Vet on
            GooglePlay (purchase the NO Ads iAP pack to remove ads). They are
            restricted from tracking users and serving behavioral ads. Users may
            purchase the no ads iAP pack(s) to remove ads.
          </Txt>
          <Txt>KIDOZ: https://kidoz.net/safe_ads_policy/</Txt>
          <Txt>Unity Ads: https://unity3d.com/legal/privacy-policy</Txt>
          <Txt>Applovin: https://www.applovin.com/privacy/</Txt>
          <Txt>Google Admob: https://policies.google.com/privacy?hl=en-US</Txt>
          <Txt>
            Our apps may contain advertising banners and users have the ability
            to close the advertisements and/or purchase an in-app no
            advertisements pack.
          </Txt>
          <Txt>
            We have enabled settings on each of these third-party ad providers
            to ensure that no behavioral tracking takes place, data collected is
            used solely to serve contextual ads and this data is not shared with
            any other third parties. All ads are subject to human review for
            appropriateness. Generally, we use accepted safeguards and security
            measures in an attempt to secure the data collected is secure and
            require that third-party ad providers agree to do the same. Despite
            our best efforts, no security measures are impenetrable.
          </Txt>
          <Txt>
            We may receive your non-personal data indirectly from the
            third-party ad providers. These third parties do not share your user
            data and such information is used to support the operation of our
            apps.
          </Txt>
          <Txt>
            For more information on the use of these third parties, please view
            the privacy policies through the links provided above.
          </Txt>
          <Txt>
            Our apps may contain, at times, information about our other apps
            with an external link to those apps on the applicable app store.
            This link may be found on the main, title screen by clicking “More
            Games.” Once you click on this link, you will be taken to the
            developer’s app store page.
          </Txt>
          <Txt>
            Our apps may contain In-App Purchase to enable additional content.
            These In-App Purchases are purchased once, and are found on a
            secured parent targeted screen. We may make small, inconsequential
            changes to this policy with or without notice to you, so you’re
            encouraged to review the policy from time to time.
          </Txt>
          <Txt>
            Our apps have been designed with the guidelines of the United States
            Federal Trade Commission (FTC) in mind, as expressed in the
            Children’s Online Privacy Protection rule (COPPA) and strive to meet
            all duties and obligations under the rule of law. The rule requires
            operators of online services, including interactive mobile apps, to
            provide notice and get parental consent prior to collecting
            information from children under 13. The Federal Trade Commission
            works for consumers to prevent fraudulent, deceptive, and unfair
            business practices and to provide information to help spot, stop,
            and avoid them. If you have any questions or concerns about our
            privacy policy, please contact us.
          </Txt>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Privacy
